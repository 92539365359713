import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        {post.feature_image ? (
                            <figure className="post-feature-image">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>

                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                            <div>
                                <p style={{background:"#ffff84", padding: "14px"}}>
                                If you are looking to streamline your business by implementing automation and workflow rules or wanting to make the best use of your CRM then we at CRM Makers would be happy to provide the right guidance and implementation.
                                </p>                            
                                <div class="btn-container">
                                    <a href="https://softily.com/bookings/hitesh.html" target="_blank" className="threed-btn" role="button" tabindex="0" style={{"--buttonColor": "#ff6e84"}}>Book a Free Consultation!<span></span><span></span><span></span><span></span>
                                        <b aria-hidden="true">Book a Free Consultation!</b>
                                        <b aria-hidden="true">Book a Free Consultation!</b>
                                        <b aria-hidden="true">Book a Free Consultation!</b>
                                        <b aria-hidden="true">Book a Free Consultation!</b>
                                    </a>
                                    <a href="https://crmmakers.com/contact.html" target="_blank" className="threed-btn" role="button" tabindex="0" style={{"--buttonColor": "#007bff"}}>Contact Us<span></span><span></span><span></span><span></span>
                                        <b aria-hidden="true">Contact Us</b>
                                        <b aria-hidden="true">Contact Us</b>
                                        <b aria-hidden="true">Contact Us</b>
                                        <b aria-hidden="true">Contact Us</b>
                                    </a>
                                </div>
                            </div>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
